<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.2"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.0003 25.3333C21.155 25.3333 25.3337 21.1546 25.3337 16C25.3337 10.8453 21.155 6.66663 16.0003 6.66663C10.8457 6.66663 6.66699 10.8453 6.66699 16C6.66699 21.1546 10.8457 25.3333 16.0003 25.3333ZM16.0003 29.3333C23.3641 29.3333 29.3337 23.3637 29.3337 16C29.3337 8.63616 23.3641 2.66663 16.0003 2.66663C8.63653 2.66663 2.66699 8.63616 2.66699 16C2.66699 23.3637 8.63653 29.3333 16.0003 29.3333Z"
      fill="currentColor"
    />
    <path
      d="M4.66699 16C3.56242 16 2.65122 15.0993 2.81495 14.0069C3.67951 8.23867 8.23904 3.67914 14.0073 2.81458C15.0997 2.65085 16.0003 3.56206 16.0003 4.66663V4.66663C16.0003 5.7712 15.0944 6.64438 14.0149 6.87825C10.4538 7.64973 7.65009 10.4535 6.87862 14.0145C6.64474 15.094 5.77156 16 4.66699 16V16Z"
      fill="currentColor"
    />
  </svg>
</template>
